@import '../../styles/colors.module.scss';

// Primo ed ultimo giorno
.CalendarDay__selected {
  background: $orange-dark;
  color: white;
  // border-radius: 50%;
  border: 0px;
}

// Giorni selezionati (primo e ultimo) on hover
.CalendarDay__selected:hover {
  background: $orange-dark;
  color: $white;
  border: 0px;
}

// range di giorni quando non hai ancora selezionato l'ultimo giorno, ma hai selezionato il primo
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $orange-light;
  border: 0px;
  color: $white;
}

// range di giorni quando hai selezionato sia il primo giorno che l'ultimo
.CalendarDay__selected_span {
  background: $orange-light;
  color: $black;
  border: 0px;
}

// range di giorni quando hai selezionato sia il primo giorno che l'ultimo on hover
.CalendarDay__selected_span:hover {
  background: $orange-light;
  color: $white;
  border: 0px;
}

// rendo invisibile l'input della data
.DateInput_input,
.DateRangePickerInput_arrow,
.DateInput {
  display: none;
}

.DateRangePickerInput {
  background-color: $grey-extra-light;
  width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

// rimuovo la griglia dei giorni
div.DatePickerContainer td {
  border: 0px !important;
}

// Only on single date picker
.SingleDatePicker_picker {
  position: unset !important;
  .DayPicker {
    border-radius: initial !important;
    box-shadow: none !important;
  }
}
