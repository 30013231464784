@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl Semi';
  src: url('./fonts/SuisseIntl-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl Book';
  src: url('./fonts/SuisseIntl-BookItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl Semi';
  src: url('./fonts/SuisseIntl-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl Book';
  src: url('./fonts/SuisseIntl-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
