@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'colors.module';
@import 'fonts.module';

:root {
  @each $name, $color in $var {
    --#{$name}: #{$color};
  }
}

*:focus {
  outline: none;
}

* {
  font-family: 'Suisse Intl';
}

div,
p {
  color: var(--black);
}

.code-input {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
