// Primary Colors
$green-dark: #197136;
$green-medium: #50aa75;
$orange-dark: #ff9500;
$orange-medium: #f5a71b;
$orange-light: #ffeacc;
$black: #1c1c1e;
$white: #ffffff;

// Secondary Colors
$red: #e36159;
$pink: #f1a09e;
$ochre: #cdb93c;
$yellow: #f2d667;
$green-light: #c2d058;
$green-black: #084b19;
$teal: #5ead9e;
$cyan: #b5d9f2;
$blue: #3478f6;
$red-brown: #cb7856;
$brown-dark: #b0825c;
$grey-extra-light: #f2f2f7;
$grey-light: #d1d1d6;
$grey-medium: #c7c7cc;
$grey: #8e8e93;

$var: (
  // Primary Colors
  'green-dark': $green-dark,
  'green-medium': $green-medium,
  'orange-dark': $orange-dark,
  'orange-medium': $orange-medium,
  'orange-light': $orange-light,
  'black': $black,
  'white': $white,

  // Secondary Colors
  'red': $red,
  'pink': $pink,
  'ochre': $ochre,
  'yellow': $yellow,
  'green-light': $green-light,
  'green-black': $green-black,
  'teal': $teal,
  'cyan': $cyan,
  'blue': $blue,
  'red-brown': $red-brown,
  'brown-dark': $brown-dark,
  'grey-extra-light': $grey-extra-light,
  'grey-light': $grey-light,
  'grey-medium': $grey-medium,
  'grey': $grey
);

:export {
  @each $name, $color in $var {
    #{$name}: #{$color};
  }
}
